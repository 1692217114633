.navbar {

  font-family: IBM Plex Mono, monospace;
  font-weight: 400;
  font-size: 1rem;
  color: #212121;
  white-space: nowrap;
  padding: 5px;
  border-radius: 5px;
  background-color: #ECEFF1;
  margin: auto;
  margin-bottom: 25px;
 
    
}
.navbar ul{
    text-align: center;
    padding: 0rem 7rem 0rem 7rem;
    display: inline-block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    
}
.navbar li {
  display: inline;
  padding: 0rem 1rem 0rem 1rem;

}

.navbar a {
    color: #424242;
    text-decoration: none;
  }

a:hover {
  -webkit-transition: background-color .4s; /* Safari */
  transition: background-color .4s;
  background-color: #b4e7f8;
}

a.unstyled {
    border-bottom: none;
    text-decoration: none;
    background-color: transparent;
  }

a{
  border-bottom: 5px solid #b4e7f8;
  text-decoration: none;
  color: #212121;
}
@media (min-width: 0px) and (max-width: 560px) { 
  .navbar {
    font-family: IBM Plex Mono, monospace;
    font-weight: 400;
    font-size: 1rem;
    color: #212121;
    white-space: nowrap;
    padding: 3px;
    border-radius: 5px;
    background-color: #ECEFF1;
    margin-bottom: 25px;
  }
  .navbar ul  {
    text-align: center;
    padding-inline-start: 0px;
  }
  .navbar .active-page {
    font-family: IBM Plex Mono, monospace;
    font-weight: 600;
    font-size: 1rem;
    color: #212121;
  }
  .navbar li {
    display:inline;
    padding: .3rem 1.5vw;
    font-size: 0.8rem;
  }
  .navbar a {
    color: #424242;
  }
 
  
  
  
}
