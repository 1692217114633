ul.resources p {
    margin-top: 8px;
    margin-bottom: 20px;
    font-style: italic;
    color: #212121;
    line-height: 1.5;
    font-size: 90%;
}

ul.resources {
    list-style-type: circle;
    padding-left: 25px;
    padding-right: 25px;
}

a{
    border-bottom: 5px solid #b4e7f8;
    text-decoration: none;
    color: #212121;
  }

.footer {
    padding: 1rem 10px 25px 10px;
    background-color: #FAFAFA;
    display: block;
}