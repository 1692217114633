.FullTable {
  border-collapse: collapse;
  overflow-x: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-family: 'Lato', serif;
}

th {
  text-align: center;
  border: none;
  margin-top: 50px;
  font-family: IBM Plex Mono, monospace;
  font-weight: 600;
  font-size: 1.2rem;
  padding: 15px 30px 10px 0px;
}

tbody {
  text-align: left;
  border: none;
  padding-top: 50px;
}

td {
  text-align: left;
  border: none;
  padding: 5px 10px 8px 10px;
  
}

tr {
  border: none;
}

tr td:first-child {
  border: none;
  border-radius: 5px 0px 0px 5px;

}
tr td:last-child {
  border: none;
  border-radius: 0px 5px 5px 0px;

}

tr:hover.body-row {
  background-color: #F5F5F5;
}

