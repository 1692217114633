.title {
    margin-top: 1.8rem;
    padding: .3rem 0 .3rem .6rem;
    border-left: 4px solid #03A9F4;
    font-family: IBM Plex Mono, monospace;
    font-weight: 600;
    font-size: 1.2rem;  
   
}
.subtitle {
    font-family: IBM Plex Mono, monospace;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.2rem;
    padding: .3rem 0 .3rem .6rem;
    color: #212121;
    border-left: 4px solid #03A9F4
}

.a{
    border-bottom: 5px solid #b4e7f8;
    text-decoration: none;
}

.main {
    margin: auto;
    min-width: 30%;
    max-width: 650px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 1.5rem;
    background-color: #FFFFFF;
  }
  
.header {

    display: inline-block;
    font-family: IBM Plex Mono, monospace;
    font-weight: 600;
    font-size: 3rem;
    padding-top: 1.5rem;
    padding-right: 0.5rem;
    margin: 0 0 1rem 0;
}


.h1 {
    display: inline-block;
    font-size: 2em;
    font-weight: bold;
}

.description {
    
    font-size: 1rem; 
    line-height: 1.6; 
    font-family: 'Lato', serif;
    font-weight: 400;
  }

