/* Mailchimp button css */

#mc_embed_signup form {
    text-align: center;
    padding: 10px 0 10px 0;
}

.mc-field-group {
    display: inline-block;
}/* positions input field horizontally */

#mc_embed_signup input.email {
    font-family: 'Lato', serif;
    font-weight: 400;
    font-size: 0.9rem; 
    border: 1px solid #ABB0B2;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    color: #343434;
    background-color: #fff;
    box-sizing: border-box;
    height: 32px;
    padding: 0px 0.4em;
    display: inline-block;
    margin: 0;
    width: 350px;
    vertical-align: top;
}

#mc_embed_signup label {
    font-family: IBM Plex Mono, monospace;
    font-weight: 600;
    font-size: 1rem;  
    display: block;
    padding-bottom: 10px;
    font-weight: bold;
}

#mc_embed_signup .clear {
    display: inline-block;
}/* positions button horizontally in line with input */

#mc_embed_signup .button {
    font-family: 'Lato', serif;
    font-weight: 400;
    font-size: 0.9rem; 
    border: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    letter-spacing: .03em;
    color: #fff;
    background-color: #03A9F4;
    box-sizing: border-box;
    height: 32px;
    line-height: 32px;
    padding: 0 18px;
    display: inline-block;
    margin: 0;
    transition: all 0.23s ease-in-out 0s;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

#mc_embed_signup .button:hover {
    background-color: #038ece;
    cursor: pointer;
}

#mc_embed_signup div#mce-responses {
    float: left;
    top: -1.4em;
    padding: 0em .5em 0em .5em;
    overflow: hidden;
    width: 90%;
    margin: 0 5%;
    clear: both;
}

#mc_embed_signup div.response {
    margin: 1em 0;
    padding: 1em .5em .5em 0;
    font-weight: bold;
    float: left;
    top: -1.5em;
    z-index: 1;
    width: 80%;
}

#mc_embed_signup #mce-error-response {
    display: none;
}

#mc_embed_signup #mce-success-response {
    color: #529214;
    display: none;
}

#mc_embed_signup label.error {
    display: block;
    float: none;
    width: auto;
    margin-left: 1.05em;
    text-align: left;
    padding: .5em 0;
}

@media (max-width: 480px) {
    #mc_embed_signup input.email {
        width: 100%;
        margin-bottom: 5px;
    }
    #mc_embed_signup .clear {
        display: block;
        width: 100%
    }
    #mc_embed_signup .button {
        width: 100%;
        margin: 0;
    }
    #mc_embed_signup label {
        font-size: 0.90rem;
    }
}

.centered {
    margin-left: auto;
    margin-right: auto;
  }

.card {
    background-color: #ECEFF1;
    border-color: #ECEFF1;
    border-radius: 5px;
    padding: 0.5rem;
}